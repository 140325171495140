<template>
  <v-card>
    <delete-dialog
      :show="deleteDialog"
      @close-modal="closeModal()"
      @delete-modal="closeModal(true)"
    />
    <!-- при отмене заказа причина -->
    <delete-dialog
      :show="cancelOrderDialog"
      :delete-button-text="'Отменить Заказ'"
      @close-modal="closeCancelModal()"
      @delete-modal="closeCancelModal(true)"
    >
      <template v-slot:body>
        <v-select
          v-model="selectedCancelTag"
          label="Причина"
          :items="STATUS.cancelTags"
          item-text="name"
          item-value="id"
          style="max-width: 90%; margin: 0 auto"
        ></v-select>
      </template>
    </delete-dialog>
    <!-- При онлайн оплате возврат товара -->
    <ReturnOrder
      :show="returnDialog"
      :order="selectedOrder"
      @close-modal="closeReturnDialog($event)"
    />
    <v-simple-table fixed-header class="orders_wrapper" width="100%">
      <thead>
        <tr class="orders_header">
          <th
            v-if="
              $power.check('admin_order_edit') ||
              $power.check('admin_order_delete')
            "
            class="orders_head"
          ></th>
          <th class="orders_head">№</th>
          <th class="orders_head">ID</th>
          <th class="orders_head">Создан</th>
          <th class="orders_head">Доставлен</th>
          <th class="orders_head">Клиент</th>
          <th class="orders_head">Адрес</th>
          <th class="orders_head">Стоимость</th>
          <th style="max-width: 130px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="departments"
              name="Филиал"
              items-name="name"
              input-key="departmentIds"
              @change="$emit('set-input')"
            />
          </th>
          <th class="orders_head">
            <AutoComplete
              :input="input"
              :items="payments"
              name="Тип оплаты"
              items-name="name"
              input-key="paymentTypes"
              @change="$emit('set-input')"
            />
          </th>
          <th class="orders_head text-left pa-2 pt-0 pb-0">
            <AutoComplete
              :input="input"
              :items="STATUS.payStatus"
              name="Статус оплаты"
              items-name="name"
              input-key="paymentStatuses"
              @change="$emit('set-input')"
            />
          </th>
          <th style="max-width: 130px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="statuses"
              name="Статус"
              items-name="name"
              input-key="statuses"
              @change="$emit('set-input')"
            />
          </th>
          <th style="max-width: 130px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="courier"
              name="Курьер"
              items-name="username"
              input-key="couriers"
              @change="$emit('set-input')"
            />
          </th>
          <th style="max-width: 130px" class="orders_head">
            <AutoComplete
              :input="input"
              :items="picker"
              name="Сборщик"
              items-name="username"
              input-key="pickers"
              @change="$emit('set-input')"
            />
          </th>
          <th class="orders_head"></th>
        </tr>
      </thead>
      <!-- Блок заказов -->
      <tbody>
        <tr v-for="(item, index) in orders" :key="item.id" class="orders_row">
          <td
            v-if="
              $power.check('admin_order_edit') ||
              $power.check('admin_order_delete')
            "
            class="orders_checkbox"
          >
            <v-checkbox
              v-model="checks[index].state"
              hide-details
              class="checkbox"
            ></v-checkbox>
          </td>
          <td class="orders_item" style="width: 20px">{{ index + 1 }}</td>
          <td class="orders_item" style="width: 50px">
            {{ item.orderId || "0" }}
          </td>
          <td class="orders_item" style="width: 50px">
            {{ getNormalDate(item.date) || "Не указана" }}
          </td>
          <td class="orders_item" style="width: 50px">
            {{ getNormalDate(item.deliveryDate) || "Не указана" }}
          </td>
          <td class="orders_item" style="width: 120px">
            {{ item.client }}
          </td>
          <td class="orders_item" style="width: 120px">
            {{ item.address }}
          </td>
          <td class="orders_item">{{ item.price }}</td>
          <td style="max-width: 130px" class="orders_item">
            <v-select
              :value="item.department"
              :items="departments"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              @change="
                $emit('change-department', {
                  order: item.orderId,
                  department: $event,
                })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="max-width: 130px" class="orders_item">
            <v-select
              :value="item.paymentType"
              :items="payments"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              @change="
                $emit('change-payment', {
                  order: item.orderId,
                  payment: $event,
                })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="max-width: 130px" class="orders_item">
            <v-select
              :background-color="getStatusColor(item.paymentStatus)"
              :value="item.paymentStatus"
              :items="STATUS.payStatus"
              item-text="name"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              @change="
                $emit('change_pay_status', {
                  statusId: $event,
                  orderId: item.orderId,
                })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="width: 120px" class="orders_item">
            <v-select
              :value="item.status"
              :items="statuses"
              item-text="name"
              item-value="id"
              :background-color="getStatusColor(item.status)"
              hide-details
              class="orders_input"
              dense
              @change="
                emitChangeStatus({
                  order: item.orderId,
                  status: $event,
                  paymentStatus: item.paymentType,
                })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="max-width: 130px" class="orders_item">
            <v-select
              :items="courier"
              :value="item.courier"
              item-text="username"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              @change="
                emitChangeCourier({
                  order: item.orderId,
                  courier: $event,
                })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="max-width: 130px" class="orders_item">
            <v-select
              v-model="item.picker"
              :items="picker"
              item-text="username"
              item-value="id"
              dense
              hide-details
              class="orders_input"
              @change="
                $emit('change-picker', { order: item.orderId, picker: $event })
              "
            >
              <template v-slot:append>
                <v-icon color="#E93E7B">mdi-menu-down</v-icon>
              </template>
            </v-select>
          </td>
          <td style="width: 45px" class="orders_item">
            <v-btn
              color="#F3993E"
              icon
              x-large
              @click="
                orderId = item.orderId;
                show = true;
              "
            >
              <v-icon>mdi-text-box</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-card-actions class="orders_action">
      <v-btn
        v-if="orders.length < last"
        color="#51C8F0"
        class="order-btns"
        @click="$emit('load-more')"
        >Показать еще 15 заказов</v-btn
      >
    </v-card-actions>
    <PasswordDialog
      :show="showPassDialog"
      @close-modal="closeCancelModal($event)"
    />
    <order-info
      :show="show"
      :order-id="orderId"
      @close_modal="show = false"
      @change_pay_status="$emit('change_pay_status', $event)"
    />
    <bottom-bar
      :id="id"
      :active="active"
      :edit="$power.check('admin_order_edit')"
      :delet="$power.check('admin_order_remove')"
      :disable="states.length > 1"
      :order="'order'"
      @show="deleteDialog = true"
    />
  </v-card>
</template>

<script>
import moment from "moment";
import OrderInfo from "@/components/Order/OrderInfo";
import BottomBar from "../BottomBar";
import DeleteDialog from "../DeleteDialog";
import PasswordDialog from "@/components/Order/PasswordDialog";
import ReturnOrder from "@/components/Order/ReturnOrder";
import AutoComplete from "@/components/Order/AutoComplete";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    BottomBar,
    OrderInfo,
    DeleteDialog,
    ReturnOrder,
    PasswordDialog,
    AutoComplete,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    checks: {
      type: Array,
      default: null,
    },
    statuses: {
      type: Array,
      default: null,
    },
    payments: {
      type: Array,
      default: null,
    },
    departments: {
      type: Array,
      default: null,
    },
    picker: {
      type: Array,
      default: null,
    },
    courier: {
      type: Array,
      default: null,
    },
    last: {
      type: Number,
      default: 0,
    },
    input: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      orderId: null,
      show: false,
      deleteDialog: false,
      cancelOrderDialog: false,
      showPassDialog: false,
      changeCourierPass: false,
      returnDialog: false,
      canBeCanceled: false,
      selectedOrder: {},
      selectedCancelTag: {},
    };
  },
  computed: {
    ...mapGetters({
      STATUS: "Status/STATE",
    }),
    orders() {
      let items = this.items;
      let orders = [];
      if (this.input.sorted === "createdAt") {
        orders = items.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
      } else {
        orders = items.sort(function (a, b) {
          return b.orderId - a.orderId;
        });
      }
      return orders;
    },
    states() {
      return this.checks.filter((x) => x.state === true);
    },
    active() {
      if (this.states.length > 0) return "active";
      return "";
    },
    id() {
      if (this.states.length !== 0) return this.states[0].id;
      return null;
    },
  },
  methods: {
    ...mapActions({
      getSingle: "Order/GET_ORDER",
    }),
    getNormalDate(val) {
      if (!val) {
        return;
      }
      const arr = val.split(",");
      if (arr.length > 1) {
        return moment(arr[0]).format("DD.MM HH:mm") + ", Предзаказ";
      }
      return moment(val).format("DD.MM HH:mm");
    },
    closeReturnDialog(payload) {
      // если закрыли диалог с удалением
      if (payload) {
        this.canBeCanceled = true;
        // если полный возврат то меняем статус
        if (payload.refundAll) {
          this.emitChangeStatus(this.selectedOrder);
          return;
        }
        this.$emit("render-orders");
      } else {
        this.selectedOrder = {};
        this.$emit("render-orders");
      }
      this.returnDialog = false;
    },
    closeModal(del) {
      if (del) {
        this.$emit("delete-button");
      }
      this.deleteDialog = false;
    },
    closeCancelModal(del) {
      if (del) {
        this.canBeCanceled = true;
        if (this.changeCourierPass) {
          this.emitChangeCourier(this.selectedOrder);
        } else {
          this.emitChangeStatus(this.selectedOrder);
        }
      } else {
        this.selectedOrder = {};
        this.$emit("render-orders");
      }
      this.cancelOrderDialog = false;
      this.showPassDialog = false;
      this.changeCourierPass = false;
    },
    emitChangeCourier(item) {
      this.selectedOrder = item;
      this.changeCourierPass = true;
      if (!this.canBeCanceled) {
        this.showPassDialog = true;
        return;
      }
      this.$emit("change-courier", this.selectedOrder);
      this.selectedOrder = {};
      this.canBeCanceled = false;
      this.changeCourierPass = false;
    },
    async emitChangeStatus(item) {
      let products = [];
      // получение списка продуктов при онлайн отмена заказа
      if (!item.products && item.status === 10 && item.paymentStatus === 4) {
        const data = await this.getSingle(item.order);
        products = data.products;
      }
      this.selectedOrder = { ...item, products };
      // введение пароля при статусе доставлен
      if (item.status === 8 && !this.canBeCanceled) {
        this.showPassDialog = true;
        return;
      }
      // возврат при онлайн оплате
      if (
        item.status === 10 &&
        item.paymentStatus === 4 &&
        !this.canBeCanceled
      ) {
        this.returnDialog = true;
        return;
      }
      // При отмене заказа
      if (item.status === 9 && !this.canBeCanceled) {
        this.cancelOrderDialog = true;
        return;
      }
      this.$emit("change-status", {
        ...item,
        tag: this.selectedCancelTag,
      });
      this.selectedOrder = {};
      this.canBeCanceled = false;
    },
    getStatusColor(item) {
      let color =
        item == 1
          ? "#d0ca02"
          : item == 2
          ? "#e2ac54"
          : item == 3
          ? "#85C5E0"
          : item == 4
          ? "#df8956"
          : item == 5
          ? "#686a69"
          : item == 6
          ? "#3A9FC9"
          : item == 7
          ? "#fbff00"
          : item == 8
          ? "#249641"
          : item == 9
          ? "#929594"
          : "#929594";
      return color;
    },
  },
};
</script>
<style lang="scss">
.orders {
  &_wrapper {
    box-sizing: border-box;
    // разрыв между строками таблицы
    .v-data-table__wrapper table {
      border-spacing: 0 1px;
    }
  }
  &_input {
    .v-input__slot {
      padding: 5px !important;
    }
    border: 1px solid #e93e7b;
    border-radius: 5px;
    align-items: center;
    .v-input__slot::before {
      border-style: none !important;
    }
  }
  &_header {
    background: linear-gradient(to right, #5d4bd0, #e93e7b);
  }
  &_head {
    background: none !important;
    color: white !important;
    padding: 0 5px !important;
    font-size: 16px !important;
  }
  &_item {
    padding: 10px 5px !important;
    border-bottom: solid 1px #e93e7a45 !important;
    &:nth-child(2) {
      padding-left: 15px !important;
    }
  }
  &_checkbox {
    padding: 0 0 0 7px !important;
    width: 20px;
    border-bottom: solid 1px white !important;
  }
  &_action {
    justify-content: center;
  }
}
.order-auto {
  .v-input__slot::before {
    border-style: none !important;
  }
}
.order-btns {
  text-transform: none;
}
.checkbox {
  margin: auto;
}
</style>
