<template>
  <div>
    <div v-if="!modalOrderInfoState" class="order-mobile-tabe__header">
      <v-autocomplete
        v-model="input.departmentIds"
        class="order-mobile-table__header_select"
        label="Филиал"
        :items="departments"
        item-text="name"
        item-value="id"
        multiple
        :clearable="departments.length !== 1"
        :readonly="departments.length === 1"
        @change="changeInput()"
      ></v-autocomplete>
      <v-autocomplete
        v-model="input.couriers"
        class="order-mobile-table__header_select"
        label="Курьер"
        multiple
        :items="courier"
        item-text="username"
        item-value="id"
        clearable
        @change="changeInput()"
      ></v-autocomplete>
    </div>
    <div v-if="!modalOrderInfoState" class="order-mobile-tabe__header">
      <v-autocomplete
        v-model="input.pickers"
        class="order-mobile-table__header_select"
        label="Сборщик"
        :items="picker"
        multiple
        item-text="username"
        item-value="id"
        clearable
        @change="changeInput()"
      ></v-autocomplete>
      <v-autocomplete
        v-model="input.statuses"
        class="order-mobile-table__header_select"
        multiple
        label="Статус"
        :items="statuses"
        item-text="name"
        item-value="id"
        clearable
        @change="changeInput()"
      >
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            <span>{{ item.name }}</span>
          </span>
          <span v-if="index === 1"> +{{ input.statuses.length - 1 }}</span>
        </template>
      </v-autocomplete>
    </div>
    <div
      ref="tableWrapper"
      class="order-mobile-table__wrapper"
      :class="{ active: modalOrderInfoState }"
    >
      <!-- модалка с доп данными о статусах -->
      <v-dialog v-model="modalStatusHistory" activator="parent" width="100%">
        <v-card :dark="$dark.get()">
          <v-card-title class="flex justify-center">Статусы</v-card-title>
          <v-card-text class="pa-0">
            <order-status
              :statuses="orderStatuses"
              :show="modalStatusHistory"
            ></order-status>
          </v-card-text>
          <v-card-actions class="flex justify-end">
            <v-btn color="primary" @click="modalStatusHistory = false"
              >закрыть окно</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- основной блок -->
      <template>
        <div
          v-for="(item, index) in orders"
          :key="item.id"
          class="order-mobile-table__order"
          :class="{ active: !modalOrderInfoState }"
          :style="'border-left: 7px solid' + getStatusColor(item.status)"
        >
          <v-checkbox
            v-if="
              $power.check('admin_order_edit') ||
              $power.check('admin_order_delete')
            "
            v-model="checks[index].state"
            class="mx-1"
          />
          <div class="order-mobile-table__order_info">
            <div class="order-mobile-table__order_id-with-name">
              <div class="order-mobile-table__order_id">
                {{ item.orderId || "0" }}
              </div>
              <div class="order-mobile-table__order_name">
                {{ item.client }}
              </div>
            </div>

            <div class="order-mobile-table__order_address">
              {{ item.address }}
            </div>
          </div>
          <div
            class="d-flex flex-column justify-center align-center"
            style="max-width: 100px; min-width: 100px"
          >
            <v-select
              class="order-mobile-table__order_status"
              :background-color="getStatusColor(item.status)"
              :value="item.status"
              hide-details
              hide-spin-buttons
              flat
              light
              :items="statuses"
              item-text="name"
              item-value="id"
              @change="
                emitChangeStatus({
                  order: item.orderId,
                  status: $event,
                  paymentStatus: item.paymentType,
                  products: item.products,
                })
              "
            ></v-select>
            <div
              class="order-mobile-table__modal-info_product-cost"
              style="margin: 0; font-size: 0.7rem"
            >
              {{ "KZT-" + item.price }}
            </div>
          </div>
          <div class="order-mobile-table__order_actions">
            <v-btn icon @click="openStatuses(item.orderId)">
              <v-icon class="order-mobile-table__custom-border">
                mdi-help-circle
              </v-icon>
            </v-btn>
            <v-btn icon x-large @click="openModalFunction(item.orderId)">
              <v-icon class="order-mobile-table__custom-border">
                mdi-alert-circle
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div
          v-if="orders.length < last && !modalOrderInfoState"
          class="order-mobile-table__button-next"
        >
          <v-btn @click="$emit('load-more')">Дальше</v-btn>
        </div>
        <bottom-bar
          :id="id"
          :active="active"
          :edit="$power.check('admin_order_edit')"
          :delet="$power.check('admin_order_remove')"
          :disable="states.length > 1"
          :order="'order'"
          @show="deleteDialog = true"
        >
        </bottom-bar>
        <delete-dialog
          :show="deleteDialog"
          @close-modal="closeModal()"
          @delete-modal="closeModal(true)"
        ></delete-dialog>
        <!-- модалка с отменой заказа -->
        <delete-dialog
          :show="cancelOrderDialog"
          :delete-button-text="'Отменить Заказ'"
          @close-modal="closeCancelModal()"
          @delete-modal="closeCancelModal(true)"
        >
          <template v-slot:body>
            <v-select
              v-model="selectedCancelTag"
              label="Причина"
              :items="STATUS.cancelTags"
              item-text="name"
              item-value="id"
              style="max-width: 90%; margin: 0 auto"
            ></v-select>
          </template>
        </delete-dialog>
        <!-- При онлайн оплате возврат товара -->
        <ReturnOrder
          :show="returnDialog"
          :order="selectedOrder"
          @close-modal="closeReturnDialog($event)"
        />
        <PasswordDialog
          :show="showPassDialog"
          @close-modal="closeCancelModal($event)"
        />
        <!-- модалка с доп информацией по заказу -->
        <MobileOrderInfo
          v-if="modalOrderInfoState"
          :order="order"
          :props-data="{
            getStatusColor,
            statuses,
            departments,
            courier,
            picker,
            delivery,
          }"
          @openSimpleSlide="openSimpleSlide($event)"
          @change-status="$emit('change-status', $event)"
          @change-department="$emit('change-department', $event)"
          @change-courier="$emit('change-courier', $event)"
          @change-picker="$emit('change-picker', $event)"
          @change_pay_status="$emit('change_pay_status', $event)"
          @change-delivery="$emit('change-delivery')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import OrderStatus from "@/components/Order/OrderStatus";
import MobileOrderInfo from "@/components/Order/MobileOrderInfo";
import BottomBar from "../BottomBar";
import DeleteDialog from "../DeleteDialog";
import ReturnOrder from "@/components/Order/ReturnOrder";
import PasswordDialog from "@/components/Order/PasswordDialog";
import { mapGetters, mapActions } from "vuex";
import api from "@/api";

export default {
  name: "AyanFrontendAdminMobileordertable",
  components: {
    BottomBar,
    OrderStatus,
    DeleteDialog,
    MobileOrderInfo,
    ReturnOrder,
    PasswordDialog,
  },
  props: {
    items: {
      type: Array,
      default: null,
    },
    checks: {
      type: Array,
      default: null,
    },
    statuses: {
      type: Array,
      default: null,
    },
    payments: {
      type: Array,
      default: null,
    },
    departments: {
      type: Array,
      default: null,
    },
    picker: {
      type: Array,
      default: null,
    },
    courier: {
      type: Array,
      default: null,
    },
    last: {
      type: Number,
      default: 0,
    },
    input: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      order: {},
      modalStatusHistory: false,
      modalOrderInfoState: false,
      show: false,
      deleteDialog: false,
      delivery: {
        date: "",
        time: "",
      },
      cancelOrderDialog: false,
      canBeCanceled: false,
      selectedOrder: {},
      selectedCancelTag: {},
      returnDialog: false,
      showPassDialog: false,
      orderStatuses: {},
      debounceId: 0,
    };
  },
  computed: {
    ...mapGetters({
      STATUS: "Status/STATE",
    }),
    orders() {
      let items = this.items;
      let orders = [];
      if (this.input.sorted === "createdAt") {
        orders = items.sort(function (a, b) {
          return new Date(a.date) - new Date(b.date);
        });
      } else {
        orders = items.sort(function (a, b) {
          return b.orderId - a.orderId;
        });
      }
      return orders;
    },
    states() {
      return this.checks.filter((x) => x.state === true);
    },
    active() {
      if (this.states.length > 0) return "active";
      return "";
    },
    id() {
      if (this.states.length !== 0) return this.states[0].id;
      return null;
    },
  },
  methods: {
    ...mapActions({
      getSingle: "Order/GET_ORDER",
    }),
    async emitChangeStatus(item) {
      let products = [];
      // получение списка продуктов при онлайн отмена заказа
      if (!item.products && item.status === 10 && item.paymentStatus === 4) {
        const data = await this.getSingle(item.order);
        products = data.products;
      }
      this.selectedOrder = { ...item, products };
      // введение пароля при статусе доставлен
      if (item.status === 8 && !this.canBeCanceled) {
        this.showPassDialog = true;
        return;
      }
      // возврат при онлайн оплате
      if (
        item.status === 10 &&
        item.paymentStatus === 4 &&
        !this.canBeCanceled
      ) {
        this.returnDialog = true;
        return;
      }
      // При отмене заказа
      if (item.status === 9 && !this.canBeCanceled) {
        this.cancelOrderDialog = true;
        return;
      }
      this.$emit("change-status", {
        ...item,
        tag: this.selectedCancelTag,
      });
      this.selectedOrder = {};
      this.canBeCanceled = false;
    },
    closeReturnDialog(del) {
      if (del) {
        this.canBeCanceled = true;
        this.emitChangeStatus(this.selectedOrder);
      } else {
        this.selectedOrder = {};
        this.$emit("render-orders");
      }
      this.returnDialog = false;
    },
    closeCancelModal(del) {
      if (del) {
        this.canBeCanceled = true;
        this.emitChangeStatus(this.selectedOrder);
      } else {
        this.selectedOrder = {};
        this.$emit("render-orders");
      }
      this.cancelOrderDialog = false;
    },
    closeModal(del) {
      if (del) {
        this.$emit$emit("delete-button");
      }
      this.deleteDialog = false;
    },
    getStatusColor(item) {
      let color =
        item == 1
          ? "#d0ca02"
          : item == 2
          ? "#e2ac54"
          : item == 3
          ? "#85C5E0"
          : item == 4
          ? "#df8956"
          : item == 5
          ? "#686a69"
          : item == 6
          ? "#3A9FC9"
          : item == 7
          ? "#fbff00"
          : item == 8
          ? "#249641"
          : item == 9
          ? "#929594"
          : "#929594";
      return color;
    },

    openSimpleSlide(number_slide) {
      this.modalOrderInfoState = false;
      setTimeout(() => {
        let tableWrapper = this.$refs["tableWrapper"];
        // Определение расстояния от начала страницы до нужного элемента
        let arrayOfIndex = this.orders.map((x) => x.orderId);
        let indexOfSlide = arrayOfIndex.indexOf(number_slide);
        let top = indexOfSlide * 118;
        // Перемотка
        tableWrapper.scrollTo(0, top);
      }, 500);
      // Получение ссылки на элемент
    },
    async openStatuses(orderId, openStatus) {
      if (this.order.id !== orderId) {
        const data = await api.Order.orderAllHistory(orderId);
        this.order = data.providerOrder;
        this.order.deliveryTime =
          data.orderPackDeliveryLogDto.deliveredExpectedTime;
        this.delivery.date = this.order.dateDelivery.substring(0, 10);
        this.delivery.time = this.order.dateDelivery.substring(11, 19);
        this.orderStatuses = data;
      }
      if (!openStatus) {
        this.modalStatusHistory = true;
      }
    },
    async openModalFunction(orderId) {
      if (this.order.id !== orderId) {
        await this.openStatuses(orderId, true);
      }
      this.modalOrderInfoState = true;
      setTimeout(() => {
        let tableWrapper = this.$refs["tableWrapper"];
        tableWrapper.scrollTo(0, 0);
      }, 100);
    },
    changeInput() {
      if (this.debounceId) {
        clearTimeout(this.debounceId);
      }
      this.debounceId = setTimeout(() => {
        console.log("da");
        this.$emit("set-input");
        clearTimeout(this.debounceId);
      }, 1500);
    },
  },
};
</script>

<style lang="scss">
.order-mobile-table__wrapper {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 95%;
  max-height: 60vh;
  overflow-y: scroll;
  overflow-x: hidden;
  font-weight: 400;
  &.active {
    max-height: 75vh;
  }
}
.order-mobile-table__order {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: lightgray;
  padding: 10px 0;
  position: absolute;
  z-index: -10000;
  & + .order-mobile-table__order {
    margin-top: 10px;
  }
  &.active {
    position: relative;
    z-index: 0;
  }
}
.order-mobile-table__order_info {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.order-mobile-table__order_id {
  font-size: 0.8rem;
  font-weight: 700;
  margin-right: 15px;
}
.order-mobile-table__order_name {
  font-size: 0.8rem;
  line-height: 100%;
  font-weight: 500;
  overflow: hidden;
}
.order-mobile-table__order_status {
  padding: 5px;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 7px;
  min-width: 100px;
  max-width: 100px;
  .v-input__slot::before {
    border: none !important;
  }
  .v-input__append-inner {
    display: none;
  }
  .v-select__selections {
    display: flex;
    justify-content: center;
    input {
      display: none;
    }
  }
}
.order-mobile-table__order_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-mobile-table__custom-border {
  border-radius: 50%;
  border: 1px solid white;
}
.order-mobile-table__order_address {
  font-size: 0.8rem;
  word-break: break-all;
}
.order-mobile-table__modal_statuses {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-mobile-table__modal_actions {
  margin-top: auto;
  padding: 20px 0px;
}
.order-mobile-table__order_id-with-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.order-mobile-table__button-next {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.order-mobile-table__modal-info_title {
  text-transform: uppercase;
  font-weight: 500;
  margin: 10px 0;
}
.order-mobile-table__modal-info_order-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  margin: 10px 0;
  .order-mobile-table__modal-info_text {
    max-width: 40%;
  }
  .order-mobile-table__modal-info_value {
    max-width: 60%;
    min-width: 60%;
  }
  .order-mobile-table__order_status {
    flex-grow: 1;
    min-width: 60%;
    padding: 0;
    text-align: start;
    .v-select__selections {
      display: flex;
      justify-content: flex-start;
      padding-left: 10%;
    }
  }
}
.order-mobile-table__modal-info_product {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}
.order-mobile-table__modal-info_product-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.order-mobile-tabe__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 20px;
}
.order-mobile-table__header_select {
  width: 50%;
  margin: 0;
  padding: 0;
  * {
    font-size: 0.7rem !important;
  }
}
</style>
