<template>
  <v-dialog
    v-model="show"
    width="80%"
    persistent
    :dark="$dark.get()"
    @click:outside="$emit('close-modal')"
  >
    <v-card>
      <v-card-title class="justify-center"> Возврат средств </v-card-title>
      <v-card-text v-if="order.products">
        <div class="d-flex">
          <v-switch
            v-model="payload.refundAll"
            :label="
              payload.refundAll
                ? 'Возврат всех продуктов'
                : 'Возврат некоторых продуктов'
            "
            class="mr-5"
          ></v-switch>
          <v-text-field
            v-model="password"
            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPass ? 'text' : 'password'"
            label="Пароль для возврата"
            :error="error"
            style="max-width: 300px"
            @click:append="showPass = !showPass"
            @input="error = false"
          ></v-text-field>
        </div>

        <v-simple-table :dark="$dark.get()" fixed-header>
          <thead>
            <tr>
              <th>Фото</th>
              <th>Название</th>
              <th>Количество</th>
              <th>Цена</th>
              <th>На возврат</th>
              <th style="width: 30px">Вернуть</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in getFilteredProducts()" :key="item.id">
              <td>
                <v-img
                  :src="item.product.images[0].fileUrl"
                  width="50"
                  height="50"
                ></v-img>
              </td>
              <td>
                {{ item.product.name }}
              </td>
              <td>
                <div class="d-flex align-center">
                  {{ item.amount.toFixed(1) + " " + item.product.measure.name }}
                </div>
              </td>
              <td>
                {{ (item.price * item.amount).toFixed(1) + "тг" }}
              </td>
              <td>
                <div v-if="productsAmount.length" class="d-flex align-center">
                  <v-btn
                    icon
                    color="primary"
                    :disabled="
                      payload.refundAll ||
                      productsAmount[index].amount ===
                        productsAmount[index].measure
                    "
                    @click="changeAmount(index)"
                    ><v-icon>mdi-minus-box</v-icon></v-btn
                  >
                  {{ productsAmount[index].amount }}
                  <v-btn
                    icon
                    color="primary"
                    :disabled="
                      payload.refundAll ||
                      item.amount === productsAmount[index].amount
                    "
                    @click="changeAmount(index, true)"
                    ><v-icon>mdi-plus-box</v-icon></v-btn
                  >
                </div>
              </td>
              <td>
                <v-checkbox
                  v-model="productsAmount[index].checked"
                  :disabled="payload.refundAll"
                  :value="item.id"
                ></v-checkbox>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-6">
        <v-btn class="mr-12 text-capitalize" @click="$emit('close-modal')"
          >Отмена</v-btn
        >
        <v-btn class="text-capitalize" color="primary" @click="returnOrder()"
          >Подтвердить</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import api from "@/api";
import ShowMessage from "@/Functions/message";
export default {
  name: "ReturnOrder",
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      payload: {
        refundAll: true,
      },
      password: "",
      showPass: false,
      error: false,
      productsAmount: [],
    };
  },
  watch: {
    order(val) {
      if (val.products) {
        this.productsAmount = this.getFilteredProducts().map((item) => {
          return {
            amount: item.amount,
            id: item.product.id,
            checked: false,
            measure: item.product.measure.coef,
          };
        });
      }
    },
  },
  methods: {
    // возвращаем продукты кроме отмененных и замененных
    getFilteredProducts() {
      if (!this.order.products) {
        return [];
      }
      return this.order.products.filter(
        (prod) => prod.productStatus.id !== 6 && prod.productStatus.id !== 4
      );
    },
    changeAmount(index, plus) {
      if (plus) {
        this.productsAmount[index].amount = +(
          this.productsAmount[index].amount + this.productsAmount[index].measure
        ).toFixed(1);
      } else {
        this.productsAmount[index].amount = +(
          this.productsAmount[index].amount - this.productsAmount[index].measure
        ).toFixed(1);
      }
    },
    async returnOrder() {
      if (this.password !== "admin212") {
        ShowMessage("Неверный пароль");
        this.error = true;
        return;
      }
      this.payload.orderId = this.order.order;
      // если частичный возврат формируем объект с ключами айдишниками, и значениями количества товара
      if (!this.payload.refundAll) {
        this.payload.providerOrderProductWithAmount = this.productsAmount.reduce(
          (accum, item) => {
            if (item.checked) {
              accum[item.id] = item.amount;
            }
            return accum;
          },
          {}
        );
      }
      const response = await api.Order.returnOrder(this.payload);
      if (response.type !== "error") {
        ShowMessage("Возврат оформлен", true);
        this.$emit("close-modal", { refundAll: this.payload.refundAll });
      } else {
        ShowMessage(response.data.message);
      }
    },
  },
};
</script>
