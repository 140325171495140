<template>
  <v-dialog
    v-model="show"
    persistent
    width="90%"
    @click:outside="$emit('close_modal')"
  >
    <loader v-if="loading"></loader>
    <v-card v-else :dark="$dark.get()">
      <v-card-title class="order-title">
        <div>Заказ {{ order.id || 0 }}</div>
        <v-btn
          rounded
          small
          color="#B1ABAD"
          dark
          class="order-title_btn"
          @click="$emit('close_modal')"
          >Закрыть окно<v-icon large color="white" class="order-title_icon"
            >mdi-close-circle</v-icon
          ></v-btn
        >
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <div class="order-block">
              <p v-if="order.promoCode">
                <span class="order-block_title">Промокод: </span
                >{{ order.promoCode }}
              </p>
              <p v-if="order.courierPassword">
                <span class="order-block_title">Код курьера: </span
                >{{ order.courierPassword }}
              </p>
              <p>
                <span class="order-block_title">Оплата: </span
                >{{ order.paymentType }}
              </p>
              <div class="order-block_box">
                <span class="order-block_title">Статус оплаты: </span>
                <v-select
                  :value="order.paymentStatus"
                  :items="STATUS.payStatus"
                  item-text="name"
                  item-value="id"
                  hide-details
                  dense
                  class="order-block_input"
                  @input="
                    $emit('change_pay_status', {
                      statusId: $event,
                      orderId: order.id,
                    })
                  "
                />
              </div>
            </div>
          </v-col>
          <v-col cols="6">
            <div class="order-block">
              <p>
                <span class="order-block_title">Источник: </span
                >{{ order.device }}
              </p>
              <p v-if="order.isPreOrder">Предзаказ</p>
              <p v-if="order.comment">
                <span class="order-block_title">Комментарий:</span>
                {{ order.comment }}
              </p>
              <div class="order-block_box">
                <span class="order-block_title">Время доставки: </span>
                <v-menu
                  v-model="showTimePicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  min-height="300px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="order.dateDelivery.replace('T', ' ')"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      hide-details
                      dense
                      class="order-block_input"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker v-model="delivery.time" format="24hr">
                  </v-time-picker>
                  <v-date-picker
                    v-model="delivery.date"
                    :min="new Date().toISOString()"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="showTimePicker = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="changeDeliveryTime()">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
            </div>
          </v-col>
        </v-row>
        <!-- Таблица с продуктами -->
        <v-row>
          <v-col cols="12">
            <v-simple-table
              v-if="order.products.length !== 0"
              style="max-height: 400px; overflow-y: auto"
              fixed-header
            >
              <thead class="text-left">
                <tr>
                  <th class="pr-4 pl-4 table-th">ID</th>
                  <th class="pr-4 pl-4 table-th">Фото</th>
                  <th class="pr-4 pl-4 table-th">Статус</th>
                  <th class="pr-4 pl-4 table-th">Название</th>
                  <th class="pr-4 pl-4 table-th">Ед.изм.</th>
                  <th class="pr-4 pl-4 table-th">Цена, тг</th>
                  <th class="pr-4 pl-4 table-th">Кол.во, шт/кг</th>
                  <th class="pr-4 pl-4 table-th">Сумма, тг</th>
                  <th class="pr-4 pl-4 table-th">Продавец</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in getSortedProducts(order.products)"
                  :key="item.id"
                  :class="
                    'product-status ' +
                    getColorByProductStatus(item.productStatus.keyName)
                  "
                >
                  <td>{{ item.id }}</td>
                  <td>
                    <v-img
                      lazy-src="@/assets/placeholder.png"
                      :src="item.image || ''"
                      width="50"
                      height="50"
                    >
                    </v-img>
                  </td>
                  <td>{{ item.productStatus.name }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.measureName }}</td>
                  <td>{{ item.price }}</td>
                  <td>{{ item.amount }}</td>
                  <td>{{ +(item.amount * item.price).toFixed(1) }}</td>
                  <td>{{ item.provider }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <order-status :statuses="statuses" :show="show"></order-status>
      </v-card-text>
      <v-card-actions class="flex justify-end">
        <v-btn
          v-if="order.paymentStatus.id >= 5"
          color="warning"
          @click="downloadInvoice()"
        >
          скачать накладную
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import OrderStatus from "@/components/Order/OrderStatus";
import loader from "@/components/Loader";
import { mapActions, mapGetters } from "vuex";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  components: { OrderStatus, loader },
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showTimePicker: false,
      delivery: {
        date: "",
        time: "",
      },
      order: {},
      statuses: {},
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      STATUS: "Status/STATE",
    }),
  },
  watch: {
    orderId(val) {
      if (val) {
        this.getOrderData(val);
      }
    },
  },

  methods: {
    ...mapActions({
      editDeliveryTime: "Order/EDIT_DELIVERY_TIME",
    }),
    async getOrderData(id) {
      this.loading = true;
      const data = await api.Order.orderAllHistory(id);
      if (data.type) {
        showMessage(data.data.message);
        this.$emit("close_modal");
        return;
      }
      this.order = data.providerOrder;
      this.delivery.date = this.order.dateDelivery.substring(0, 10);
      this.delivery.time = this.order.dateDelivery.substring(11, 19);
      this.statuses = data;
      this.loading = false;
    },
    async downloadInvoice() {
      const response = await api.File.downloadInvoiceForOrder(this.order.id);
      if (response.status === 200) {
        // не ссылка на файл приходит как везде а сам файл
        downloadFile(response.data, `Накладная на заказ №${this.order.id}`);
        return;
      }
      showMessage(`Ошибка скачивания файла`);
    },
    getSortedProducts(products) {
      const result = [...products].sort(
        (product1, product2) => product1.id - product2.id
      );
      return result;
    },
    /**
     * Функция возвращающая класс со стилями (по БЭМу) в зависимости от передаваемого статуса
     * @param {String} statusKeyName статус; определяет статус продукта (e.g.: 'canceled', 'not_confirmed', 'confirmed')
     */
    getColorByProductStatus(statusKeyName) {
      switch (statusKeyName) {
        case "not_confirmed":
          return "product-status_not-confirmed";
        case "confirmed":
          return "product-status_confirmed";
        case "on_agreement":
          return "product-status_on-agreement";
        case "canceled":
          return "product-status_canceled";
        case "agreed":
          return "product-status_agreed";
        case "replaced":
          return "product-status_replaced";
        case "approved_analog":
          return "product-status_approved-analog";
        case "added":
          return "product-status_added";
        default:
          return;
      }
    },
    async changeDeliveryTime() {
      let data = {
        time: this.delivery.date + "T" + this.delivery.time,
        id: this.order.id,
      };
      let response = await this.editDeliveryTime(data);
      if (response?.type === "error") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      } else {
        this.showTimePicker = false;
        this.$emit("close_modal", true);
      }
    },
  },
};
</script>

<style lang="scss">
.product-status {
  color: inherit;
}

.product-status_not-confirmed {
  color: rgba(66, 66, 66);
}

.product-status_confirmed {
  color: rgb(0, 0, 0);
}

.product-status_on-agreement {
  color: rgb(0, 0, 0);
}

.product-status_canceled {
  color: rgba(246, 59, 59);
}

.product-status_agreed {
  color: rgba(41, 149, 41);
}

.product-status_replaced {
  color: rgb(98, 98, 98);
  text-decoration: line-through;
}

.product-status_approved-analog {
  color: rgba(41, 149, 41);
}

.product-status_added {
  color: rgb(41, 68, 149);
}
.table-th {
  color: #5d4bd0 !important;
}
.order-title {
  justify-content: space-between;
  &_btn {
    padding-right: 0 !important;
  }
  &_icon {
    margin-right: -5px;
  }
}
.order-block {
  border-radius: 15px;
  border: 2px solid #51c8f0;
  padding: 20px;
  p {
    margin-bottom: 0;
  }
  &_title {
    font-weight: 700;
    color: black;
  }
  &_box {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  &_input {
    max-width: 40% !important;
  }
}
</style>
