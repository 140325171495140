<template>
  <div class="order-mobile-table__modal-info">
    <div class="order-mobile-table__modal-info_head">
      <div class="order-mobile-table__order_info">
        <div class="order-mobile-table__order_id-with-name">
          <div class="order-mobile-table__order_id">
            {{ order.id || "0" }}
          </div>
          <div class="order-mobile-table__order_name">
            {{ order.client }}
          </div>
        </div>

        <div class="order-mobile-table__order_address">
          {{ order.address }}
        </div>
        <div class="order-mobile-table__order_phone-number">
          {{ order.phone }}
        </div>
      </div>
      <v-btn icon x-large @click="$emit('openSimpleSlide', order.id)">
        <v-icon class="order-mobile-table__custom-border">mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="order-mobile-table__modal-info_top">
      <div class="order-mobile-table__modal-info_order-action">
        <p class="order-mobile-table__modal-info_text">Дата создания</p>
        <p class="order-mobile-table__modal-info_value">
          {{ getDate(order.dateDelivery) || "Не указан" }}
          {{ order.isPreOrder ? "Предзаказ" : "" }}
        </p>
      </div>

      <div class="order-mobile-table__modal-info_order-action">
        <p class="order-mobile-table__modal-info_text">Время доставки</p>
        <p class="order-mobile-table__modal-info_value">
          {{ getDate(order.deliveryTime) || "Не указан" }}
        </p>
      </div>

      <div
        v-if="order.courierPassword"
        class="order-mobile-table__modal-info_text"
      >
        Код курьера
      </div>
      <div
        v-if="order.courierPassword"
        class="order-mobile-table__modal-info_value"
      >
        {{ order.courierPassword }}
      </div>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Статус заказа</div>
      <v-select
        class="order-mobile-table__order_status"
        :background-color="propsData.getStatusColor(order.status.id)"
        :value="order.status.id"
        hide-details
        hide-spin-buttons
        flat
        light
        :items="propsData.statuses"
        item-text="name"
        item-value="id"
        @change="$emit('change-status', { order: order.id, status: $event })"
      ></v-select>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Сумма заказа</div>
      <div class="order-mobile-table__modal-info_product-cost product-cost">
        {{ order.sumFinal + " KZT" }}
      </div>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Филиал</div>
      <v-select
        class="order-mobile-table__modal-info_value"
        :value="order.departmentId"
        :items="propsData.departments"
        item-text="name"
        item-value="id"
        dense
        hide-details
        outlined
        @change="
          $emit('change-department', {
            order: order.id,
            department: $event,
          })
        "
      ></v-select>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Курьер</div>
      <v-select
        class="order-mobile-table__modal-info_value"
        :items="propsData.courier"
        :value="order.courierId"
        item-text="username"
        item-value="id"
        dense
        hide-details
        outlined
        @change="
          $emit('change-courier', {
            order: order.id,
            courier: $event,
          })
        "
      ></v-select>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Cборщик</div>
      <v-select
        v-model="order.pickerId"
        class="order-mobile-table__modal-info_value"
        :items="propsData.picker"
        item-text="username"
        item-value="id"
        dense
        hide-details
        outlined
        @change="$emit('change-picker', { order: order.id, picker: $event })"
      ></v-select>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Статус оплаты:</div>
      <v-select
        v-model="order.paymentStatus"
        class="order-mobile-table__modal-info_value"
        :items="STATUS.payStatus"
        item-text="name"
        item-value="id"
        dense
        hide-details
        outlined
        @input="
          $emit('change_pay_status', {
            statusId: $event,
            orderId: order.id,
          })
        "
      />
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Способ оплаты:</div>
      <div class="order-mobile-table__modal-info_value">
        {{ order.paymentType }}
      </div>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_title">Источник</div>
      <div class="order-mobile-table__modal-info_value">
        {{ order.device }}
      </div>
    </div>
    <div
      v-if="order.comment"
      class="order-mobile-table__modal-info_order-action"
    >
      <div class="order-mobile-table__modal-info_text">Комментарий</div>
      <div class="order-mobile-table__modal-info_value">
        {{ order.comment }}
      </div>
    </div>
    <div class="order-mobile-table__modal-info_order-action">
      <div class="order-mobile-table__modal-info_text">Доставить</div>
      <v-menu
        v-model="showTimePicker"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        min-height="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            class="order-mobile-table__modal-info_time"
            :value="order.dateDelivery.replace('T', ' ')"
            label="Время доставки"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            hide-details
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker v-model="delivery.time" format="24hr"> </v-time-picker>
        <v-date-picker
          v-model="delivery.date"
          :min="new Date().toISOString()"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="showTimePicker = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="changeDeliveryTime()"> OK </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <v-divider></v-divider>

    <!-- блок с продуктами -->
    <div class="order-mobile-table__modal-info_title">Состав заказа</div>
    <div class="order-mobile-table__modal-info_product-list">
      <div
        v-for="item in order.products"
        :key="item.id"
        class="order-mobile-table__modal-info_product"
      >
        <v-img
          lazy-src="@/assets/placeholder.png"
          :src="item.image || ''"
          max-width="50"
          max-height="50"
        ></v-img>
        <div class="pl-5">
          <div :class="getColorByProductStatus(item.productStatus.keyName)">
            {{ item.name }}
          </div>
          <div class="order-mobile-table__modal-info_product-info">
            <div class="product-cost">
              {{ item.price + " ₸" }}
            </div>
            <div class="product-count">
              {{ "X " + item.amount + " " + item.measureName }}
            </div>
            <div class="product-count">
              {{ "Всего " + +(item.amount * item.price).toFixed(1) + " ₸" }}
            </div>
            <div class="product-count">
              {{ "Поставщик: " + item.provider }}
            </div>
          </div>
          <div>
            {{ "Статус: " + item.productStatus.name }}
          </div>
        </div>
      </div>
      <v-btn
        v-if="order.paymentStatus.id >= 5"
        color="warning"
        @click="downloadInvoice()"
      >
        скачать накладную
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  name: "MobileOrderInfo",
  props: {
    order: {
      type: Object,
      default: null,
    },
    propsData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showTimePicker: false,
      delivery: {
        date: "",
        time: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      STATUS: "Status/STATE",
    }),
  },
  watch: {
    propsData() {
      this.delivery = { ...this.propsData.delivery };
    },
  },
  methods: {
    ...mapActions({
      editDeliveryTime: "Order/EDIT_DELIVERY_TIME",
    }),
    async downloadInvoice() {
      const response = await api.File.downloadInvoiceForOrder(this.order.id);
      if (response.status === 200) {
        downloadFile(response.data, `Накладная на заказ №${this.order.id}`);
        return;
      }
      showMessage(`Ошибка скачивания файла`);
    },
    getDate(val) {
      return moment(val).format("DD.MM.YY HH:mm");
    },
    async changeDeliveryTime() {
      let data = {
        time: this.delivery.date + "T" + this.delivery.time,
        id: this.order.id,
      };
      let response = await this.editDeliveryTime(data);
      if (response?.type === "error") {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.data.message,
        });
      } else {
        this.showTimePicker = false;
        this.modalOrderInfoState = false;
        this.$emit("change-delivery");
      }
    },
    getColorByProductStatus(statusKeyName) {
      switch (statusKeyName) {
        case "not_confirmed":
          return "product-status_not-confirmed";
        case "confirmed":
          return "product-status_confirmed";
        case "on_agreement":
          return "product-status_on-agreement";
        case "canceled":
          return "product-status_canceled";
        case "agreed":
          return "product-status_agreed";
        case "replaced":
          return "product-status_replaced";
        case "approved_analog":
          return "product-status_approved-analog";
        case "added":
          return "product-status_added";
        default:
          return;
      }
    },
  },
};
</script>

<style lang="scss">
.order-mobile-table__modal-info {
  border-radius: 20px;
  background-color: lightgrey;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  &_time {
    max-width: 40%;
  }
  &_text {
    display: flex;
    justify-content: space-between;
  }
}
.order-mobile-table__modal-info_head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.product-status_not-confirmed {
  color: rgba(66, 66, 66);
}

.product-status_confirmed {
  color: rgb(0, 0, 0);
}

.product-status_on-agreement {
  color: rgb(0, 0, 0);
}

.product-status_canceled {
  color: rgba(246, 59, 59);
}

.product-status_agreed {
  color: rgba(41, 149, 41);
}

.product-status_replaced {
  color: rgb(98, 98, 98);
  text-decoration: line-through;
}

.product-status_approved-analog {
  color: rgba(41, 149, 41);
}

.product-status_added {
  color: rgb(41, 68, 149);
}

.product-count {
  margin-left: 10px;
}
.product-cost {
  color: green;
  background-color: lightgreen;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}
</style>
